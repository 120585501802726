import React from "react"
import YouTubeIcon from "@material-ui/icons/YouTube"
import InstagramIcon from "@material-ui/icons/Instagram"
import FacebookIcon from "@material-ui/icons/Facebook"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <div
      style={{
        marginTop: "80vh",
        height: "6vh",
        backgroundColor: "#E51C21",
        marginBottom: 0,
        padding: 10,
        paddingBottom: 15,
        textAlign: "center",
      }}
    >
      <Link to="https://www.instagram.com/harrison_investments/">
        <InstagramIcon style={{ marginLeft: 30, color: "#fff" }} />
      </Link>
      <Link to="https://www.facebook.com/harrisonassetmanagement">
        <FacebookIcon style={{ marginLeft: 30, color: "#fff" }} />
      </Link>
      <Link to="https://www.youtube.com/">
        <YouTubeIcon style={{ marginLeft: 30, color: "#fff" }} />
      </Link>
    </div>
  )
}

export default Footer
