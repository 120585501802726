import React, { useEffect, useState } from "react"
import { Home } from "@material-ui/icons"
import { Link } from "gatsby"
import { navigate } from "gatsby"

const Header = () => {
  const [isLoggedin, setIsLoggedIn] = useState()

  useEffect(() => {
    var token = localStorage.getItem("token")
    if (!token) {
      setIsLoggedIn(false)
    } else {
      setIsLoggedIn(true)
    }
  }, [])

  return (
    <div style={{ height: "14vh", padding: 10 }}>
      <img src={require("../../assets/Logo/logo.png")} width={180} />

      <div style={{ float: "right", padding: 20, display: "inline" }}>
        <Link to="/instagram">
          <button
            type="button"
            className="btn btn-outline-danger"
            style={{ marginLeft: 10 }}
          >
         Instagram feed
          </button>
        </Link>

        <button
          type="button"
          className="btn btn-danger"
          style={{ marginLeft: 10, backgroundColor: "#E51C21" }}
          onClick={() => {
            {
              if (isLoggedin) {
                localStorage.removeItem("token")
                navigate("/login")
              } else {
                navigate("/login")
              }
            }
          }}
        >
          {isLoggedin ? "Logout" : "Login"}
        </button>
      </div>
      <div
        style={{ float: "right", padding: 20, marginTop: 6, display: "inline" }}
      >
        <Link to="/">
          <Home style={{ marginLeft: 10, color: "#6AA5E3" }} />
        </Link>
      </div>
    </div>
  )
}

export default Header
