import React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    height: "16vh",
    [theme.breakpoints.up("xs")]: { marginTop: "10vh", height: "70vh" },
    [theme.breakpoints.up("xl")]: { marginTop: "0vh", height: "80vh" },
    [theme.breakpoints.up("lg")]: { marginTop: "0vh", height: "80vh" },
    padding: 10,
    position: "absolute",
    overflowY: "auto",
    overflowX: "hidden",
    width: "100vw",
  },
}))
const HomeLayout = ({ children }) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Header />
      <div className={classes.root}>{children}</div>
      <Footer />
    </React.Fragment>
  )
}

export default HomeLayout
